import { fr } from 'vuetify/lib/locale';

export default {
  ...fr,

  'generic': {
    response: {
      networkError: 'Votre réseau ne permet pas à cet appareil de se connecter à notre serveur. Essayez un autre réseau!',
      unexpectedError: `Erreur inattendue, demandez à votre représentant de contacter l'assistance.`,
    },
  },

  'invalid': 'invalides',
  'welcome-header': 'Bienvenue',
  'embedded-video-id': 'ar4345mqdv',
  'rep-number-field': 'Entrez le Numéro du Représentant',
  'find-rep-button': 'Trouver le Représentant',
  'invalid-rep-number': 'Numéro de représentant invalide',
  'store-modules-rep-notFound': `Ce représentant n'a pas été trouvé. Veuillez essayer un autre numéro de représentant.`,
  'meeting-code-prompt': 'Ensuite, pour confirmer, veuillez entrer votre code de rencontre à quatre chiffres.',
  'meeting-code-field': 'Code de Rencontre',
  'meeting-button-label': 'Confirmer la Rencontre',
  'meeting-confirmation-success': 'Rencontre trouvée pour',
  'meeting-confirmation-retry': 'Entrez un Numéro Nouveau',
  'continue': 'Continuer',
  'invalid-meeting-number': 'Code de rencontre invalide',
  'meeting-does-not-exist': `Cette rencontre n'a pas été trouvé. Veuillez essayer un autre code de rencontre.`,
  'survey': {
    'title': 'Réactions',
    'button': {
      true: 'Soumettre Réactions',
      false: 'Continuer',
    },
    'field-label': 'Commenter',
  },
  'change-rep-option': 'Changer de Représentant',
  'call-button-label': 'Appelle',
  'text-button-label': 'Texter',
  'share-button-label': 'Partager',
  'sharing-button-label': 'Partage',
  'shared-status': 'partagé',
  'share-manual': 'Recommandez des personnes sympas à votre rep!',
  'share-first-name-field-label': 'Prénom',
  'share-last-name-field-label': 'Nom de Famille',
  'share-mobile-number': 'Numéro Mobile',
  'share-previously-recommended': 'Vous avez déjà recommandé',
  'share-error-pre-name': `Nous n'avons pas pu enregistrer votre mise à jour pour`,
  'share-error-post-name': `Veuillez vérifier que leur numéro est valide et que vous disposez d'une bonne connexion internet.`,
  'grant-access-prompt': `Pour vous permettre de partager cette expérience avec d'autres personnes, l'application devra accéder à la liste de contacts de votre téléphone.`,
  'grant-access-header': 'Trouvons des gens sympas à recommander à votre représentant!',
  'grant-access-paragraph-1': `Pour vous permettre de partager cette expérience avec d'autres personnes, l'application devra accéder à la liste de contacts de votre téléphone.`,
  'grant-access-paragraph-2': `Accorder l'accès à vos contacts ne partagera pas immédiatement toutes informations sur vos amis avec votre représentant des ventes.`,
  'grant-access-paragraph-3': `Ce n'est qu'après avoir sélectionnés et confirmés vos amis que les informations sur ces contacts seront partagées avec votre représentant. MyCutcoRep synchronise les données de contact pour permettre à votre représentant de faire un suivi avec les recommandations données.`,
  'share-dialog-title': 'Confirmer les Informations de Partage',
  'share-dialog-header': 'Partager les coordonnées de',
  'faq-1-header': 'Quelles informations sont partagées ?',
  'faq-1-answer': `Seuls le prénom, le nom et le numéro de téléphone d'un contact sont partagés.`,
  'faq-2-header': 'Comment ces informations sont-elles partagées ?',
  'faq-2-answer': `Elles sont téléchargés sur un système sécurisé et privé qui permet à votre représentant d'y accéder.`,
  'faq-3-header': 'Comment ces informations sont-elles utilisées ?',
  'faq-3-answer': `Seul votre représentant peut y accéder. Cutco n'accédera pas directement, ni ne vendra jamais ces informations. Elles seront purgées lorsque votre représentant n'en aura plus besoin.`,
  'grant-access-button-label': `Accorder l'Accès`,
  'grant-access-error': `Incapable d'accéder aux contacts. Veuillez accorder l'accès ci-dessous afin de partager.`,
  'grant-access-reprompt': `Sans accorder l'accès, vous ne pourrez pas aider votre représentant!`,
  'privacy-policy': 'Politique de Confidentialité',
  'contact-list-prompt': 'Choisissez des personnes sympas à référer',
  'search-field-placeholder': 'Rechercher',
  'no-search-results': 'Fin de résultats de recherche',
  'contact': 'Contact',
  'contacts': 'Contacts',
  'with': 'avec',
  'share-filter-label': 'Voir Partagé',
  'remove-filter-label': 'Voir Tout',
  'introduce-button-label': 'Présenter',
  'intro-done': 'Introduit',
  'introduction-title': 'Introduction',
  'change-intro-button-label': 'Changer',
  'select-language': 'Sélectionnez la Langue',
  'text-message-prompt': 'Réviser et modifier le message',
  'take-selfie-label': 'Prendre un Selfie',
  'remove-photo-button-label': 'Supprimer la Photo',
  'copy-to-sms-button-label': 'Créer un Message',
  'create-message-disclaimer': `Vous aurez une autre chance de réviser et de modifier le message avant de l'envoyer.`,
  'landing-fix-name': 'Corriger le Nom',
  'landing-phoneNumber': 'Numéro de Téléphone',
  'landing-cancel': 'Annuler',
  'landing-save': 'Enregistrer',
  'landing-next': 'Prochain',
  'landing-skip': 'Passer',
  'required-field': 'Champs requis',
  'invalid-max-length': 'Le champ ne peut pas contenir plus de {{}} caractères',
  'invalid-phone-number': 'Numéro de téléphone invalide',
  'landing-empty': `J'ai déjà les numéros dont j'avais besoin, mais merci quand même!`,
  'landing-success': `C'est tout, merci beaucoup!`,
  'rep-number': 'Numéro de Représentant',
  'rep-bio': 'Biographie',
  'not-found-title': 'Non Trouvé',
  'not-found-body': `Nous n'avons pas pu trouver votre dossier. Veuillez contacter votre représentant.`,
  'landing-introduction-greeting': 'Salut',
  'landing-introduction-body': `Merci d'avance de m'aider avec ceci. Sur la page suivante se trouvent des personnes pour lesquelles je n'ai pas de numéros de téléphone. Si vous pouriez fournir les chiffres pour n'importe lequel d'entre eux, ce serait vraiment apprécié. Merci beaucoup!`,
  'landing-introduction-proceed': 'Voir la Liste',
  'landing-description': `Aidez votre représentant à la fin de la démonstration en lui donnant des commentaires à l'aide de l'application mobile MyCutcoRep.`,
  'landing-download-button': `Télécharger l'Application`,
  'landing-content-description': `Une occasion d'aider quelqu'un aspirant d'être entrepreneur sur leur cheminement vers le succès.`,
  'landing-content-call-to-action': 'Téléchargez cette application pour fournir à votre représentant de précieux commentaires.',
  'landing-hide': 'Masquer',
  'landing-read-more': 'Lire la suite',
  'landing-rep-greeting': 'Salut',
  'landing-schedule-appointment': 'Prendre rendez-vous',
  'landing-appointment-type-title': 'Comment souhaitez-vous rencontrer {{}}?',
  'landing-appointment-type-in-person': 'En personne',
  'landing-appointment-private-virtual': 'Virtuellement',
  'landing-appointment-private-virtual-request': 'Demander Virtuel',
  'landing-appointment-private-virtual-sms': `Salut, {{}}, c'est userName ! J'aimerais prendre rendez-vous virtuel avec vous dès que possible. J'attends de vos nouvelles avec impatience !`,
  'landing-appointment-private-in-person': 'Chez moi',
  'landing-appointment-private-in-person-request': 'Demander à domicile',
  'landing-appointment-private-in-person-sms': `Salut, {{}}, c'est userName ! J'aimerais prendre rendez-vous en personne avec vous dès que possible. J'attends de vos nouvelles avec impatience !`,
  'landing-appointment-event-in-person': `Lors d'un événement`,
  'landing-appointment-event-in-person-sms': `Salut, {{}}, c'est userName ! J'aimerais savoir quand aura lieu votre prochain événement. J'attends de vos nouvelles avec impatience !`,
  'landing-shop-now': 'Acheter maintenant',
  'landing-privacy': 'confidentialité',
  'landing-terms': 'conditions',
  'landing-privacy-policy': '<h2>Politique de confidentialité</h2>' +
    `La Vector Marketing Corporation ("nous") respecte et s'engage à protéger votre vie privée. C'est pourquoi nous avons adopté cette politique de confidentialité applicable à et couvrant : www.mycutcorep.app ("ce site web").<br/><br/>` +
    `Cette politique de confidentialité vous informe sur la manière dont vos informations personnelles sont traitées et utilisées. Nous nous engageons à n'utiliser vos informations personnelles que de manière compatible avec cette politique de confidentialité.` +
    '<h3>Collecte et utilisation des informations personnelles</h3>' +
    `Nous ne consignons pas automatiquement les données personnelles, et nous ne relions pas automatiquement les informations consignées par d'autres moyens à des données personnelles sur des individus spécifiques. Nous ne recueillons pas d'informations sur nos visiteurs à partir d'autres sources, telles que des registres publics, des organismes ou des organisations privées.<br/><br/>` +
    `Si vous choisissez de soumettre des informations sur notre application/site web, ces informations seront traitées en toute confidentialité et ne seront jamais partagées avec quiconque en dehors de notre organisation et des fournisseurs de l'organisation. Aucune information que vous fournissez ne sera jamais utilisée à des fins de marketing par quelqu'un d'autre.` +
    '<h3>Cookies</h3>' +
    `Certaines de nos pages web utilisent des "cookies" et d'autres technologies de suivi. Un "cookie" est un petit fichier texte pouvant être utilisé pour recueillir des informations sur l'activité du site web. Nous pouvons suivre des informations non personnellement identifiables telles que les pages visitées, les motifs de clics et les dates et heures auxquelles notre site est consulté.` +
    'Ces informations sont recueillies de manière agrégée, et nous ne constituons pas de profils de navigation individuels pour des visiteurs spécifiques.<br/><br/>' +
    `Notre utilisation de cookies et d'autres technologies de suivi nous permet d'améliorer notre site web. Nous n'utilisons pas les cookies pour stocker des données personnelles, et nous ne relions pas les informations non personnelles stockées dans les cookies à des données personnelles sur des individus spécifiques.` +
    `Nous ne partageons pas ces informations agrégées, non personnellement identifiables, avec quiconque en dehors de notre organisation et des fournisseurs de l'organisation.` +
    '<h3>Avis juridique</h3>' +
    `Bien que nous ferons tout notre possible pour préserver la vie privée de l'utilisateur, nous pourrions être amenés à divulguer des informations personnelles lorsque la loi l'exige, si nous croyons de bonne foi qu'une telle action est nécessaire pour respecter une procédure judiciaire en cours, une ordonnance du tribunal ou une procédure légale nous étant signifiée.<br/><br/>` +
    `Si nous décidons de modifier notre politique de confidentialité, nous publierons ces changements dans cette déclaration de confidentialité et à d'autres endroits que nous jugerons appropriés, afin que vous soyez toujours informé des informations que nous recueillons, de la manière dont nous les utilisons, et dans quelles circonstances, le cas échéant, nous les divulguerions.<br/><br/>` +
    'Nous utiliserons vos informations uniquement conformément à la politique de confidentialité en vigueur au moment où les informations ont été recueillies.',
};
