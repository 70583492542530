import { es } from 'vuetify/lib/locale';

export default {
  ...es,

  'generic': {
    response: {
      networkError: 'Su red no permite que este dispositivo se conecte a nuestro servidor. ¡Prueba una red diferente!',
      unexpectedError: 'Error inesperado, haga que su representante se comunique con el soporte.',
    },
  },

  'invalid': 'invalido',
  'welcome-header': 'Bienvenido',
  'embedded-video-id': '12m5ux361h',
  'rep-number-field': 'Ingrese el Número de Rep',
  'find-rep-button': 'Encontrar Rep',
  'invalid-rep-number': 'Número de rep inválido',
  'store-modules-rep-notFound': 'No encontré este representante, ¡intente con un número de representante diferente!',
  'meeting-code-prompt': 'Próximo, ingrese su código de reunión de cuatro dígitos para confirmarlo.',
  'meeting-code-field': 'Código de Reunión',
  'meeting-button-label': 'Confirmar Reunión',
  'meeting-confirmation-success': '¡Reunión encontrada para',
  'meeting-confirmation-retry': 'Ingrese un Nuevo Número',
  'continue': 'Continuar',
  'invalid-meeting-number': 'Código de reunión inválido',
  'meeting-does-not-exist': 'No encontré esta reunión, ¡intente con otro código de reunión!',
  'survey': {
    'title': 'Reacción',
    'button': {
      true: 'Entregar Reacción',
      false: 'Continuar',
    },
    'field-label': 'Comentar',
  },
  'change-rep-option': 'Cambiar Rep',
  'call-button-label': 'Llamada',
  'text-button-label': 'Texto',
  'share-button-label': 'Compartir',
  'sharing-button-label': 'Compartiendo',
  'shared-status': 'compartido',
  'share-manual': '¡Recomiende a algunas personas agradables a su rep!',
  'share-first-name-field-label': 'Primer Nombre',
  'share-last-name-field-label': 'Apellido',
  'share-mobile-number': 'Número Móvil',
  'share-previously-recommended': 'Ya ha recomendado a',
  'share-error-pre-name': 'No pudimos guardar su actualización para',
  'share-error-post-name': 'Verifique que su número sea válido y que tenga una buena conexión a Internet.',
  'grant-access-prompt': 'Para que pueda compartir esta experiencia con otras personas, la aplicación deberá acceder a la lista de contactos de su teléfono.',
  'grant-access-header': '¡Busquemos personas agradables para recomendar a su rep!',
  'grant-access-paragraph-1': 'Para que pueda compartir esta experiencia con otras personas, la aplicación deberá acceder a la lista de contactos de su teléfono.',
  'grant-access-paragraph-2': 'Al otorgar acceso a sus contactos, no se compartirá inmediatamente ninguna información sobre sus amigos con su representante de ventas.',
  'grant-access-paragraph-3': 'Solo después que seleccione y confirme a sus amigos elegidos, la información sobre esos contactos se compartirá con su rep. MyCutcoRep sincroniza los datos de contacto para permitir que tu representante dé seguimiento a las referencias proporcionadas.',
  'share-dialog-title': 'Confirmar Intercambio de Información',
  'share-dialog-header': 'Comparta la información de contacto de',
  'faq-1-header': '¿Qué información se comparte?',
  'faq-1-answer': 'Solo se comparte el nombre, el apellido y el número de teléfono de un contacto.',
  'faq-2-header': '¿Cómo se comparte esa información?',
  'faq-2-answer': 'Se carga en un sistema privado y seguro que permite que su representante lo acceda.',
  'faq-3-header': '¿Cómo se usa esa información?',
  'faq-3-answer': 'Solo su representante lo puede acceder. Cutco no accederá directamente ni venderá esta información. Se eliminará cuando su representante ya no lo necesite.',
  'grant-access-button-label': 'Conceder Acceso',
  'grant-access-error': 'No es posible acceder a los contactos. Otorgue acceso a continuación para compartir.',
  'grant-access-reprompt': 'Sin otorgar acceso, ¡no podrá ayudar a su rep!',
  'privacy-policy': 'Política de Privacidad',
  'contact-list-prompt': 'Seleccione personas agradables para recomendar',
  'search-field-placeholder': 'Buscar',
  'no-search-results': 'No más resultados de búsqueda',
  'contact': 'Contacto',
  'contacts': 'Contactos',
  'with': 'con',
  'share-filter-label': 'Ver Compartido',
  'remove-filter-label': 'Ver Todo',
  'introduce-button-label': 'Introducir',
  'intro-done': 'Introducido',
  'introduction-title': 'Introducción',
  'change-intro-button-label': 'Cambiar',
  'select-language': 'Seleccionar Idioma',
  'text-message-prompt': 'Revisar y editar mensaje',
  'take-selfie-label': 'Toma una Selfie',
  'remove-photo-button-label': 'Eliminar Foto',
  'copy-to-sms-button-label': 'Crear Mensaje',
  'create-message-disclaimer': 'Tendrá otra oportunidad de revisar y editar el mensaje antes de enviarlo.',
  'landing-fix-name': 'Fijar Nombre',
  'landing-phoneNumber': 'Numero de Telefono',
  'landing-cancel': 'Cancelar',
  'landing-save': 'Guardar',
  'landing-next': 'Próximo',
  'landing-skip': 'Saltar',
  'required-field': 'Campo requerido',
  'invalid-max-length': 'El campo no debe ser mayor a {{}} caracteres',
  'invalid-phone-number': 'Numero de telefono invalido',
  'landing-empty': 'Ya tengo los números que necesitaba, ¡pero gracias de todos modos!',
  'landing-success': 'Eso es todo, ¡muchas gracias!',
  'rep-number': 'Número de rep',
  'rep-bio': 'Biografía',
  'not-found-title': 'No Encontrado',
  'not-found-body': 'No pudimos encontrar los registros adecuados para usted. Comuníquese con su rep.',
  'landing-introduction-greeting': 'Hola',
  'landing-introduction-body': 'Gracias por ayudarme con esto. En la siguiente página hay algunas personas para las que no tengo números. Si pudiera proporcionar los números de cualquiera de ellos, sería de gran ayuda. ¡Gracias de nuevo!',
  'landing-introduction-proceed': 'Ver Lista',
  'landing-description': 'Ayude a su representante después de su demostración dándoles comentarios utilizando la aplicación móvil MyCutcoRep.',
  'landing-download-button': 'Descargar la App',
  'landing-content-description': 'Una oportunidad para ayudar a un aspirante a emprender en su viaje hacia el éxito.',
  'landing-content-call-to-action': 'Descargue esta aplicación para brindarle a su representante comentarios valiosos.',
  'landing-hide': 'Ocultar',
  'landing-read-more': 'Leer más',
  'landing-rep-greeting': 'Hola',
  'landing-schedule-appointment': 'Programar Cita',
  'landing-appointment-type-title': '¿Cómo te gustaría encontrarte con {{}}?',
  'landing-appointment-type-in-person': 'En persona',
  'landing-appointment-private-virtual': 'Virtualmente',
  'landing-appointment-private-virtual-request': 'Solicitar Virtual',
  'landing-landing-appointment-private-virtual-sms': '¡Hola, {{}}, soy userName! Me gustaría reservar una cita virtual de Cutco contigo tan pronto como tengas disponibilidad. ¡Espero tu respuesta con interés!',
  'landing-appointment-private-in-person': 'En mi casa',
  'landing-appointment-private-in-person-request': 'Solicitar en persona',
  'landing-appointment-private-in-person-sms': '¡Hola, {{}}, soy userName! Me gustaría reservar una cita en persona de Cutco contigo tan pronto como tengas disponibilidad. ¡Espero tu respuesta con interés!',
  'landing-appointment-event-in-person': 'En un evento',
  'landing-appointment-event-in-person-sms': '¡Hola, {{}}, soy userName! Me gustaría saber cuándo será tu próximo evento. ¡Espero tu respuesta con interés!',
  'landing-shop-now': 'Comprar Ahora',
  'landing-privacy': 'privacidad',
  'landing-terms': 'términos',
  'landing-privacy-policy': '<h2>Política de Privacidad</h2>' +
    'Vector Marketing Corporation ("nosotros") respeta y se compromete a proteger su privacidad. Es por eso que hemos adoptado esta Política de Privacidad aplicable y que cubre: www.mycutcorep.app ("este sitio web").<br/><br/>' +
    'Esta Política de Privacidad le informa cómo se procesa y utiliza su información personal. Nos comprometemos a utilizar su información personal solo de formas compatibles con esta Política de Privacidad.' +
    '<h3>Recolección y Uso de Información Personal</h3>' +
    'No registramos automáticamente datos personales ni vinculamos información registrada automáticamente por otros medios con datos personales sobre individuos específicos. No recopilamos información sobre nuestros visitantes de otras fuentes, como registros públicos o entidades, u organizaciones privadas.<br/><br/>' +
    'Si elige enviar información en nuestra aplicación/sitio web, esa información se mantendrá en la más estricta confidencialidad y nunca se compartirá con nadie fuera de nuestra organización y los proveedores de la organización. La información que proporcione nunca se utilizará con fines de marketing por parte de ninguna otra persona.' +
    '<h3>Cookies</h3>' +
    'Algunas de nuestras páginas web utilizan "cookies" y otras tecnologías de seguimiento. Una "cookie" es un pequeño archivo de texto que se puede utilizar para recopilar información sobre la actividad del sitio web. Podemos rastrear información no identificable personalmente, como páginas visitadas, patrones de clic y fechas y horas en que se accede a nuestro sitio.' +
    'Esta información se recopila de manera agregada y no recopilamos perfiles de navegación individuales para visitantes específicos.<br/><br/>' +
    'Nuestro uso de cookies y otras tecnologías de seguimiento nos permite mejorar nuestro sitio web. No utilizamos cookies para almacenar datos personales ni vinculamos información no personal almacenada en cookies con datos personales sobre individuos específicos.<br/><br/>' +
    'No compartimos esta información agregada, no identificable personalmente, con nadie fuera de nuestra organización y los proveedores de la organización.' +
    '<h3>Aviso Legal</h3>' +
    'Aunque haremos todo lo posible para preservar la privacidad del usuario, es posible que necesitemos divulgar información personal cuando lo exija la ley, cuando tengamos la creencia de buena fe de que tal acción es necesaria para cumplir con un procedimiento judicial actual, una orden judicial o un proceso legal presentado ante nosotros.<br/><br/>' +
    'Si decidimos cambiar nuestra política de privacidad, publicaremos esos cambios en esta declaración de privacidad y en otros lugares que consideremos apropiados para que siempre esté al tanto de qué información recopilamos, cómo la usamos y en qué circunstancias, si las hay, la divulgaríamos.<br/><br/>' +
    'Utilizaremos su información solo de acuerdo con la política de privacidad bajo la cual se recopiló la información.',
};
